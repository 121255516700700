import { useEffect, useRef, useState } from "react";
import { useGetOwnAccountQuery } from "../../store/services/profileServices";
import NotificationPopUp from "../NotificationPopUp/NotificationPopUp";
import { useLazyUserNotificationsQuery, useUserNotificationsQuery } from "../../store/services/notificationServices";
import { useDispatch, useSelector } from "react-redux";
import { sidebarActions } from "../../store/sidebarSlice";
import { toast } from "react-toastify";
import { newNotificationActions } from "../../store/newNotificationSlice";
import { Link } from "react-router-dom";

const NavBar = () => {
  const [showPopup, setShowPopup] = useState(false);
  const dispatch = useDispatch();
  const isSidebarOpen = useSelector((state) => state.sidebar.isOpen);
  const sidebarRef = useRef(null);
  const { data: userData, isLoading: userLoading } = useGetOwnAccountQuery();
  // const { data: notificationsData, isLoading: notificationsLoading } =
  //   useUserNotificationsQuery();

  const [trigger, { data: notificationsData, isLoading: notificationsLoading }] = useLazyUserNotificationsQuery();

  const [bellTriggered, setBellTriggered] = useState(false)

  const today = new Date().toLocaleDateString("sq-AL", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });

  const newNotification = useSelector(state => state.newNotification)

  useEffect(() => {
    if (newNotification.trueOnce) {
      setBellTriggered(true)
    }
  }, [newNotification])

  useEffect(() => {
    console.log({ notificationsData })
  }, [notificationsData])

  const notifications = notificationsData
    ? notificationsData.map((notification) => ({
      text: notification.title,
      body: notification.body,
      date: notification.received_at
    }))
    : [];

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target) &&
        isSidebarOpen
      ) {
        dispatch(sidebarActions.triggerSidebar());
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dispatch, isSidebarOpen]);

  return (
    <div>
      <div className="sm:p-4 py-5 border-gray-200 border-dashed rounded-lg">
        <div className="flex sm:hidden justify-between items-center">
          <Link to="/">
            <img src="/full_logo.svg" alt="Logo" />
          </Link>
          <div className="flex items-center gap-3">
            <div className="relative">
              <div
                onClick={() => {
                  setShowPopup(!showPopup)
                  trigger()
                  setBellTriggered(false)
                }}
                className="bg-lighterGrey rounded-full flex items-center justify-center w-10 h-10 cursor-pointer"
              >
                <img src="/notification.png" alt="Njoftimet" />

                {bellTriggered && (
                  <span className="absolute top-0 right-0 w-3 h-3 bg-red-500 rounded-full border border-white"></span>
                )}
              </div>
            </div>
            <div onClick={() => dispatch(sidebarActions.triggerSidebar())}>
              <img
                src="hamburgerMenu.svg"
                className="cursor-pointer"
                alt="Menu"
              />
            </div>
          </div>
        </div>
        <div className="flex justify-between">
          <div className="hidden sm:block">
            <p className="text-2xl font-semibold">
              Mirë së erdhe {userLoading ? "Loading..." : userData?.name}!
            </p>
            <p className="text-base font-normal text-greyColor">
              Menaxhoj faturat e juaja leht dhe shpejt tani!
            </p>
          </div>
          <div className="items-center justify-center gap-2 hidden sm:flex">
            <div className="relative">
              <div
                onClick={() => {
                  setShowPopup(!showPopup)
                  trigger()
                  setBellTriggered(false)
                }}
                className="bg-lighterGrey rounded-full flex items-center justify-center w-10 h-10 cursor-pointer"
              >
                <img src="/notification.png" alt="Njoftimet" />
              </div>

              {bellTriggered && (
                <span className="absolute top-0 right-0 w-3 h-3 bg-red-500 rounded-full border border-white"></span>
              )}
            </div>

            <div className=" border-2 rounded-xl border-lightGrey gap-3 items-center justify-center px-3 py-1 hidden sm:flex">
              <div className="bg-lighterGrey rounded-lg flex items-center justify-center w-10 h-10">
                <img src="/calendar.png" alt="Kalendari" />
              </div>
              <div>
                <p className="text-primary text-base font-normal">Data</p>
                <p className="text-primary text-base font-medium">{today}</p>
              </div>
            </div>
          </div>
        </div>

        {showPopup && (
          <NotificationPopUp
            notifications={notificationsData}
            onClose={() => setShowPopup(false)}
          />
        )}
      </div>
    </div>
  );
};

export default NavBar;
