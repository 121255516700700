import React, { useState } from "react";
import { useNotificationtDetailsQuery } from "../../store/services/notificationServices";
import DetailNotificationPopUp from "../DetailNotificationPopUp/DetailNotificationPopUp";

const NotificationBorder = ({ data }) => {
  const [selectedNotificationId, setSelectedNotificationId] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const { data: notificationDetails, isLoading } = useNotificationtDetailsQuery(
    selectedNotificationId,
    { skip: !selectedNotificationId }
  );

  const handleEyeClick = (notificationId) => {
    setSelectedNotificationId(notificationId);
    setIsPopupOpen(true);
  };

  const extractFirstSentence = (htmlString) => {
    const text = htmlString.replace(/<[^>]*>/g, "");
    const firstSentence = text.split(".")[0];
    return firstSentence ? `${firstSentence}` : text;
  };

  return (
    <>
      <div className="sm:hidden block">
        {data.map((notification, index) => (
          <div
            key={index}
            className="relative flex items-center justify-between border-b py-4 px-3 sm:w-full"
          >
            <img
              alt="Fatura"
              src={`${process.env.REACT_APP_BACKEND_HOST}/api/${notification.image_path}`}
              className="w-14 h-14 rounded-md object-cover"
            />

            <div className="flex flex-col">
              <h6 className="text-slate-800 text-[14px] font-medium">
                {notification.title}
              </h6>
              <p className="text-slate-400 text-[12px]">
                {extractFirstSentence(notification.body)}
              </p>
            </div>

            <div className="flex items-center gap-3">
              <img
                src="/eye.png"
                alt="Eye icon"
                className="cursor-pointer"
                onClick={() => handleEyeClick(notification.notification_id)}
              />
            </div>
          </div>
        ))}
      </div>

      {isPopupOpen && (
        <DetailNotificationPopUp
          isOpen={isPopupOpen}
          onClose={() => setIsPopupOpen(false)}
          isLoading={isLoading}
          notificationDetails={notificationDetails}
        />
      )}
    </>
  );
};

export default NotificationBorder;
