import React, { useState } from "react";

const LatestScanning = ({ data }) => {
  const itemsPerPage = 3;
  const cardWidth = 286;
  const gap = 16;
  const scrollWidth = cardWidth + gap;

  console.log({ data })

  const [currentIndex, setCurrentIndex] = useState(0);

  const maxIndex = data?.[0]?.data?.length
    ? Math.max(0, Math.ceil(data[0].data.length / itemsPerPage) - 1)
    : 0;

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => Math.min(prevIndex + 1, maxIndex));
  };

  return (
    <div className="pt-4 pb-4 pl-4 sm:block hidden">
      <div className="flex justify-between items-center mb-4">
        <p className="text-xl font-medium">Skanimet e fundit</p>
        <div className="gap-2 sm:flex hidden">
          <button
            className="w-9 h-9 bg-primary rounded-md flex items-center justify-center cursor-pointer disabled:opacity-50"
            onClick={handlePrev}
            disabled={currentIndex === 0}
          >
            <img src="/leftArrow.png" alt="Previous" />
          </button>
          <button
            className="w-9 h-9 bg-primary rounded-md flex items-center justify-center cursor-pointer disabled:opacity-50"
            onClick={handleNext}
            disabled={currentIndex === maxIndex}
          >
            <img src="/rightArrow.png" alt="Next" />
          </button>
        </div>
      </div>

      <div className="overflow-hidden" style={{ maxWidth: "100%" }}>
        <div
          className="flex sm:flex-row sm:gap-y-4 transition-transform duration-300"
          style={{
            transform: `translateX(-${currentIndex * scrollWidth * itemsPerPage
              }px)`,
            gap: `${gap}px`,
          }}
        >
          {Array.isArray(data) &&
            data.length > 0 &&
            data[0]?.data?.length > 0 ? (
            data[0].data.map((item) => {
              let firstImage = null;
              if (item.image_path) {
                try {
                  const images = item.image_path;
                  firstImage = Array.isArray(images) ? images[0] : null;
                } catch (error) {
                  console.error("Invalid image_path format", error);

                  if (typeof item.image_path === "string") {
                    firstImage = item.image_path;
                  }
                }
              }

              return (
                <div
                  key={`${item.id}-${item.created_at}`}
                  className="relative flex flex-col rounded-lg border border-slate-200 bg-white shadow-sm flex-shrink-0 sm:w-full"
                  style={{ width: `${cardWidth}px` }}
                >
                  <div className="flex items-center p-3">
                    {firstImage ? (
                      <img
                        alt="Fatura"
                        src={`${process.env.REACT_APP_BACKEND_HOST}/api/${firstImage}`}
                        className="w-14 h-14 rounded-md mr-4 object-cover"
                      />
                    ) : (
                      <div className="w-14 h-14 bg-gray-200 rounded-md mr-4"></div>
                    )}
                    <div>
                      <h6 className="text-slate-800 text-[15px] font-medium">
                        {item.business_name}
                      </h6>
                      <p className="text-slate-400 text-xs">
                        {new Date(item.created_at).toLocaleString()}
                      </p>
                    </div>
                    <div className="ml-auto flex gap-2">
                      <img src="/dots.png" alt="More options" />
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="text-center py-4">No receipts found.</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LatestScanning;
