import { ClipLoader } from "react-spinners";

export default function SubmitButton({ value, loading }) {
  return (
    <button
      type="submit"
      className="w-full bg-primary text-white rounded-md py-[8px] px-4 cursor-pointer flex items-center justify-center gap-2"
      disabled={loading}
    >
      {loading ? <ClipLoader size={20} color="#ffffff" /> : value}
    </button>
  );
}
