import React, { useEffect, useState } from "react";

const DetailNotificationPopUp = ({
  isOpen,
  onClose,
  isLoading,
  notificationDetails,
}) => {
  console.log("🚀 ~ notificationDetails:", notificationDetails);

  const [notificationData, setNotificationData] = useState({
    title: "",
    body: "",
    received_at: "",
  });
  useEffect(() => {
    if (notificationDetails) {
      const normalizedData = {
        title: notificationDetails.title || notificationDetails.text || "",
        body: notificationDetails.body || "",
        received_at:
          notificationDetails.date || notificationDetails.received_at || "",
      };
      setNotificationData(normalizedData);
    }
  }, [notificationDetails]);

  const fieldLabels = {
    title: "Titulli i njoftimit",
    body: "Mesazhi i njoftimit",
    received_at: "Data",
  };

  const renderImages = () => {
    if (!notificationDetails || !notificationDetails.image_path) {
      return <p className="text-center">Njoftimi nuk ka imazh.</p>;
    }
    const imagePaths = Array.isArray(notificationDetails.image_path)
      ? notificationDetails.image_path
      : [notificationDetails.image_path];
    if (imagePaths.length === 0) {
      return <p className="text-center">Njoftimi nuk ka imazh.</p>;
    }

    return (
      <div
        className={`grid ${
          imagePaths.length > 1
            ? "flex flex-row gap-4 max-h-[500px] overflow-y-auto"
            : "grid-cols-1"
        } justify-center items-center`}
      >
        {imagePaths.map((image, index) => (
          <div key={index} className="flex justify-center">
            <img
              src={`${process.env.REACT_APP_BACKEND_HOST}/api/${image}`}
              alt="Njoftimi"
              className="rounded-md max-w-full object-cover"
            />
          </div>
        ))}
      </div>
    );
  };

  return !isOpen ? null : (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-[90%] max-w-4xl max-h-[800px] overflow-y-auto relative">
        <button onClick={onClose} className="absolute top-4 right-4">
          ✕
        </button>

        <h2 className="text-lg font-semibold pb-3">Shikoni Njoftimin</h2>

        <div className="flex flex-col md:flex-row gap-6">
          <div className="md:w-1/3 flex flex-col items-center overflow-y-auto max-h-[500px]">
            {isLoading ? <p>Loading...</p> : renderImages()}
          </div>

          <div className="md:w-2/3">
            {isLoading ? (
              <p>Loading...</p>
            ) : notificationDetails ? (
              <div className="gap-4">
                {Object.keys(fieldLabels).map((field) => (
                  <div key={field} className="pb-4">
                    <label className="block text-sm font-medium text-gray-700">
                      {fieldLabels[field]}
                    </label>
                    <p className="mt-1 p-3 text-sm block w-full border rounded-md shadow-sm bg-gray-100">
                      {field === "body" ? (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: notificationData.body,
                          }}
                        />
                      ) : (
                        notificationData[field]
                      )}
                    </p>
                  </div>
                ))}
              </div>
            ) : (
              <p className="text-center">Nuk ka njoftime</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailNotificationPopUp;
