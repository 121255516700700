import BgVector from "./BgVector";

export default function AuthWrapper({ children }) {
  return (
    <div>
      <div className="relative min-h-screen flex justify-center items-center p-8 z-40">
        <div className="flex flex-col justify-between items-center h-full z-30">
          <div className="w-[360px] p-4 md:p-0">{children}</div>
        </div>
        <div className="absolute bottom-0 left-0 z-20">
          <BgVector />
        </div>
      </div>
    </div>
  );
}
