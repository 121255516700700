import { Navigate } from "react-router-dom";
import { useSubscribeMutation } from "../../store/services/notificationServices";
import { useEffect, useState } from "react";
import { io } from "socket.io-client";
import { jwtDecode } from 'jwt-decode'
import { newNotificationActions } from "../../store/newNotificationSlice";
import { useDispatch, useSelector } from "react-redux";
import { getToken, messaging } from "../../../src/firebase";
import { toast } from "react-toastify";
import { getFCMToken } from "webtonative/Firebase/Messaging"

export const ProtectedRoute = ({ token, loading, children }) => {
  const [subscribe] = useSubscribeMutation();
  const [id, setId] = useState(null)
  const dispatch = useDispatch()

  const newNotification = useSelector(state => state.newNotification.isTrue)

  useEffect(() => {
    if (token) {
      const data = jwtDecode(token?.access_token)
      setId(data?.id)
    }
  }, [token])

  useEffect(() => {
    if (newNotification) {
      toast.info('You have new notifications');
      dispatch(newNotificationActions.triggerOffNewNotification())
    }
  }, [newNotification])

  useEffect(() => {
    if (id) {
      const socket = io(process.env.REACT_APP_BACKEND_HOST)

      socket.on('connect', () => {
        console.log('on connect')
      })

      socket.emit('online', id);

      socket.on('new_notification', (data) => {
        if (data) {
          dispatch(newNotificationActions.triggerOnNewNotification())
        }
      })
    }
  }, [id])

  useEffect(() => {
    if (token) {
      if (localStorage.getItem('fcmToken')) {
        return;
      }
      const userAgent = navigator.userAgent.toLowerCase();
      if(/w2n/i.test(userAgent)) {
        getFCMToken({
            callback:function(data){
              const token = data.token;
              const platform = 'ios-mobile'
              const response = subscribe({ token, platform }).unwrap();
              localStorage.setItem('fcmToken', token)
            }
        })
      }
      
      if(/webtonative/i.test(userAgent)) {
        getFCMToken({
            callback:function(data){
              const token = data.token;
              const platform = 'android-mobile'
              const response = subscribe({ token, platform }).unwrap();
              localStorage.setItem('fcmToken', token)
            }
        })
      }
      
      const handleSubscription = async () => {
        try {
          let platform = ''
          const permission = await Notification.requestPermission();
          if (permission === "granted") {
            const savedToken = localStorage.getItem("fcmToken");

            if (typeof window !== 'undefined') {
              
              if (/android/i.test(userAgent)) {
                platform = 'android-browser'
              } else if (/iphone|ipad|ipod/i.test(userAgent)) {
                platform = 'ios-browser'
              } else {
                platform = 'web'
              }
            }

            if (savedToken) {
              console.log("FCM Token (from cache):", savedToken);
              return savedToken;
            }

            const token = await getToken(messaging, {
              vapidKey: "BNUuyENcQoXyATBTPxC_kDZFMU-bAX2l-kIQ_PXlSwIRDHFcEzv_NtyW9AiyuyN5aXDpsaqbux3uhN22y1C20zQ",
            });

            if (token) {
              localStorage.setItem("fcmToken", token);
            }
            // qoje ne backend
            const response = await subscribe({ token, platform }).unwrap();

            console.log({ response })

            console.log("FCM Token:", token);
            return token;
          } else {
            console.error("Notification permission denied");
            localStorage.removeItem("fcmToken");
          }
        } catch (error) {
          console.error("Error requesting notification permission:", error);
        }
      };
      handleSubscription();
    }
  }, [token]);

  if (loading) {
    return <div>Loading...</div>;
  }
  if (!token) {
    console.log("navigating to login because no token");
    return <Navigate to="/login" replace />;
  }
  return children;
};
