import "./App.css";
import { Routes, Route, Navigate } from "react-router-dom";
import Login from "./pages/Login";
import Register from "./pages/Register";
import ChangePassword from "./pages/ChangePassword";
import ScrollToTop from "./components/auth/ScrollToTop";
import VerifyUser from "./pages/VerifyUser";
import { useEffect, useState } from "react";
import { ProtectedRoute } from "./components/auth/ProtectedRoute";
import Profile from "./pages/Profile";
import ForgotPassword from "./pages/ForgotPassword";
import Home from "./pages/Home";
import SuccessProcess from "./pages/SuccessProcess";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "./store/authSlice";
import ArchivedReceipts from "./components/Receipt/ArchivedReceipts";
import Notifications from "./pages/Notifications";

function App() {
  const access_token = localStorage.getItem("giz_access_token");
  const refresh_token = localStorage.getItem("giz_refresh_token");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const redux_token = useSelector((state) => state.auth.token);

  useEffect(() => {
    if (access_token && refresh_token) {
      setIsLoggedIn(true);
      dispatch(authActions.addToken({ access_token, refresh_token }));
    }
    setLoading(false);
  }, [access_token, refresh_token]);

  return (
    <div className="">
      <ScrollToTop />
      <Routes>
        {/* Protected route */}
        <Route
          path="/"
          element={
            <ProtectedRoute token={redux_token} loading={loading}>
              <Home />
            </ProtectedRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <ProtectedRoute token={redux_token} loading={loading}>
              <Profile />
            </ProtectedRoute>
          }
        />
        <Route
          path="/archived-receipts"
          element={
            <ProtectedRoute token={redux_token} loading={loading}>
              <ArchivedReceipts />
            </ProtectedRoute>
          }
        />
        <Route
          path="/notifications"
          element={
            <ProtectedRoute token={redux_token} loading={loading}>
              <Notifications />
            </ProtectedRoute>
          }
        />
        {/* Public routes */}
        <Route
          path="/login"
          element={redux_token ? <Navigate to="/" replace /> : <Login />}
        />
        <Route
          path="/register"
          element={redux_token ? <Navigate to="/" replace /> : <Register />}
        />
        <Route
          path="/forgot_password"
          element={
            redux_token ? <Navigate to="/" replace /> : <ForgotPassword />
          }
        />
        <Route
          path="/verify"
          element={redux_token ? <Navigate to="/" replace /> : <VerifyUser />}
        />
        <Route
          path="/change_password"
          element={
            redux_token ? <Navigate to="/" replace /> : <ChangePassword />
          }
        />
        <Route
          path="/success"
          element={
            redux_token ? <Navigate to="/" replace /> : <SuccessProcess />
          }
        />
      </Routes>
    </div>
  );
}

export default App;
