import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyAFk9q2m5Zjy8eSQF7106qmSCLIELf9-fc",
    authDomain: "tak-lottery.firebaseapp.com",
    projectId: "tak-lottery",
    storageBucket: "tak-lottery.firebasestorage.app",
    messagingSenderId: "675849625340",
    appId: "1:675849625340:web:c2f05d91255065e3234179",
    measurementId: "G-9W4GWMFT8R"
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export { messaging, getToken, onMessage };