import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./authSlice";
import { authApi } from "./services/authServices";
import { profileApi } from "./services/profileServices";
import forgetPasswordSlice from "./forgetPasswordSlice";
import { receiptApi } from "./services/receiptServices";
import { notificationsApi } from "./services/notificationServices";
import sidebarSlice from "./sidebarSlice";
import newNotificationSlice from "./newNotificationSlice";

const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    forgetPassword: forgetPasswordSlice.reducer,
    sidebar: sidebarSlice.reducer,
    newNotification: newNotificationSlice.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [profileApi.reducerPath]: profileApi.reducer,
    [receiptApi.reducerPath]: receiptApi.reducer,
    [notificationsApi.reducerPath]: notificationsApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authApi.middleware,
      profileApi.middleware,
      receiptApi.middleware,
      notificationsApi.middleware
    ),
});

export default store;
