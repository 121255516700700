import { createSlice } from "@reduxjs/toolkit";

const initialNewNotificationState = { isTrue: false, trueOnce: false };

const newNotificationSlice = createSlice({
  name: "newNotificationSlice",
  initialState: initialNewNotificationState,
  reducers: {
    triggerOnNewNotification(state) {
      state.isTrue = true
      state.trueOnce = true
    },
    triggerOffNewNotification(state) {
      state.isTrue = false
    },
  },
});

export const newNotificationActions = newNotificationSlice.actions;
export default newNotificationSlice;