import { useState } from "react";
import LogoHeader from "../components/auth/LogoHeader";
import InputField from "../components/auth/InputField";
import SubmitButton from "../components/SubmitButton";
import { FcGoogle } from "react-icons/fc";
import { Link, useNavigate } from "react-router-dom";
import { useRegisterMutation } from "../store/services/authServices";
import { ClipLoader } from "react-spinners";
import AuthWrapper from "../components/auth/AuthWrapper";

export default function Register() {
  const [userData, setUserData] = useState({
    name: "",
    phone_number: "",
    email: "",
    password: "",
    birthdate: new Date().toISOString().split("T")[0],
    confirm_password: "",
    zip_code: "",
  });

  const [registerUser, { isLoading }] = useRegisterMutation();
  const navigate = useNavigate();

  const handleUserDataChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const checkPasswords = () => {
    if (userData.password.length < 8) {
      alert("Password must include at least 8 characters");
      return false;
    }
    if (userData.password !== userData.confirm_password) {
      alert("Passwords don't match");
      return false;
    }
    return true;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (checkPasswords()) {
      try {
        const result = await registerUser(userData).unwrap();
        console.log("User created:", result);
        navigate("/login");
      } catch (error) {
        console.error("Failed to create item:", error);
      }
    }
  };

  return (
    <AuthWrapper>
      <LogoHeader />
      <div className="text-center flex flex-col gap-2">
        <h1 className="text-[30px] font-semibold">Regjistrohu</h1>
        <h3 className="text-gray-600">
          Shkruani të dhënat e kërkuara më poshtë
        </h3>
      </div>
      <div className="my-4 mt-8 z-20">
        <form onSubmit={handleFormSubmit}>
          <div className="flex flex-col gap-6">
            <InputField
              type="text"
              name="name"
              label="Emri & Mbiemri"
              value={userData.name}
              placeholder="Shkruani emrin dhe mbiemrin tuaj"
              onChange={handleUserDataChange}
              required={true}
            />
            <InputField
              type="text"
              name="phone_number"
              label="Numri i telefonit"
              value={userData.phone_number}
              placeholder="Shkruani numrin e telefonit"
              onChange={handleUserDataChange}
              required={true}
            />
            <InputField
              type="email"
              name="email"
              label="Email"
              value={userData.email}
              placeholder="Shkruani emailin tuaj"
              onChange={handleUserDataChange}
              required={true}
            />
            <InputField
              type="date"
              name="birthdate"
              label="Data e lindjes"
              value={userData.birthdate}
              placeholder="Selektoni daten"
              onChange={handleUserDataChange}
              required={true}
            />
            <InputField
              type="password"
              name="password"
              label="Fjalëkalimi"
              value={userData.password}
              placeholder="••••••••"
              onChange={handleUserDataChange}
              required={true}
            />
            <InputField
              type="password"
              name="confirm_password"
              label="Konfirmo fjalëkalimin"
              value={userData.confirm_password}
              placeholder="••••••••"
              onChange={handleUserDataChange}
              required={true}
            />
            <InputField
              type="text"
              name="zip_code"
              label="Kodi postar"
              value={userData.zip_code}
              placeholder="Shkruani kodin postar"
              onChange={handleUserDataChange}
              required={true}
            />
          </div>
          <div className="flex justify-between my-6">
            <div className="flex gap-2 items-center">
              <input
                type="checkbox"
                className="w-4 h-4 accent-primary border text-blue-600 border-gray-300 rounded-xl"
              />
              <div className="text-sm">Më kujto për 30 ditë</div>
            </div>
            <Link to="/forgot_password">
              <div className="text-sm text-primary font-semibold hover:underline">
                Keni harruar fjalëkalimin
              </div>
            </Link>
          </div>
          <div className="flex flex-col items-center gap-4 my-4">
            <SubmitButton value="Regjistrohu" loading={isLoading} />
            <div className="w-full bg-white text-gray-700 rounded-md py-[6px] px-4 flex gap-2 items-center justify-center border border-gray-300">
              <FcGoogle size={26} />
              <span className="font-medium">Hyr me Google</span>
            </div>
          </div>
        </form>
        <div className="text-center text-sm text-gray-600 mt-8">
          Keni një llogari?{" "}
          <Link to="/login">
            <span className="font-semibold text-primary hover:underline">
              Kyqu
            </span>
          </Link>
        </div>
      </div>
    </AuthWrapper>
  );
}
