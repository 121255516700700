import { PiArrowLeftBold, PiArrowRightBold } from "react-icons/pi";

export default function Pagination({ currentPage, totalPages, onPageChange }) {
  return (
    <div className="hidden sm:flex justify-between items-center p-4 px-6 text-sm">
      <div className="w-1/4 flex font-bold">
        <button
          className="px-3 py-2 border border-gray-300 rounded-lg flex items-center gap-2 disabled:cursor-auto"
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <span>
            <PiArrowLeftBold fontSize={20} />
          </span>
          Previous
        </button>
      </div>
      <div className="w-1/2 flex justify-center">
        <div className="flex items-end gap-2">
          {Array.from({ length: totalPages }, (_, i) => i + 1).map((num, index) => {
            if (totalPages <= 10) {
              return (
                <div key={index}>
                  {num === currentPage && (
                    <div
                      className="bg-gray-100 border border-gray-100 p-2 px-4 rounded-lg font-bold"
                      key={num}
                    >
                      {num}
                    </div>
                  )}
                  {num !== currentPage && (
                    <div
                      className="bg-white border border-white p-2 px-4 rounded-lg cursor-pointer hover:border-gray-100"
                      key={num}
                      onClick={() => onPageChange(num)}
                    >
                      {num}
                    </div>
                  )}
                </div>
              );
            }
            if (num === 1 && currentPage - 3 > 1) {
              return (
                <>
                  <div
                    className="bg-white border border-white p-2 px-4 rounded-lg cursor-pointer hover:border-gray-100"
                    key={num}
                    onClick={() => onPageChange(num)}
                  >
                    {num}
                  </div>
                  {currentPage - 4 > 1 && <div className="p-2">...</div>}
                </>
              );
            }
            if (num < 0) {
              return;
            }
            if (num > totalPages) {
              return;
            }
            if (num === currentPage) {
              return (
                <>
                  {[currentPage - 3, currentPage - 2, currentPage - 1].map(
                    (page) => {
                      if (page < 1) {
                        return;
                      }
                      if (page > totalPages) {
                        return;
                      }
                      return (
                        <div
                          className="bg-white border border-white p-2 px-4 rounded-lg cursor-pointer hover:border-gray-100"
                          key={page}
                          onClick={() => onPageChange(page)}
                        >
                          {page}
                        </div>
                      );
                    }
                  )}
                  {num === currentPage && (
                    <div
                      className="bg-gray-100 border border-gray-100 p-2 px-4 rounded-lg font-bold"
                      key={num}
                      onClick={() => onPageChange(num)}
                    >
                      {num}
                    </div>
                  )}
                  {[currentPage + 1, currentPage + 2, currentPage + 3].map(
                    (page) => {
                      if (page > totalPages) {
                        return;
                      }
                      if (page < 0) {
                        return;
                      }
                      return (
                        <div
                          className="bg-white border border-white p-2 px-4 rounded-lg cursor-pointer hover:border-gray-100"
                          key={page}
                          onClick={() => onPageChange(page)}
                        >
                          {page}
                        </div>
                      );
                    }
                  )}
                </>
              );
            }
            if (num === totalPages && currentPage + 3 < totalPages) {
              return (
                <>
                  {currentPage + 4 < totalPages && (
                    <div className="p-2">...</div>
                  )}
                  <div
                    className="bg-white border border-white p-2 px-4 rounded-lg cursor-pointer hover:border-gray-100"
                    key={num}
                    onClick={() => onPageChange(num)}
                  >
                    {num}
                  </div>
                </>
              );
            }
          })}
        </div>
      </div>
      <div className="w-1/4 flex justify-end font-bold">
        <button
          className="px-3 py-2 border border-gray-300 rounded-lg flex items-center gap-2 disabled:cursor-auto"
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
          <PiArrowRightBold fontSize={20} />
        </button>
      </div>
    </div>
  );
}
